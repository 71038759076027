import React, { useState, useEffect } from "react";

const images = [
  "/image/n.jpg",
  "/image/n.jpg",
  "/image/n1.jpg",
  "/image/n2.jpg",
];

const Banner: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        if (prevIndex === images.length - 1) {
          return 1; // Jump directly to index 1 after the last image
        }
        return prevIndex + 1;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative h-[80vh] w-full flex justify-center items-start flex-col pl-2 md:pl-6 overflow-hidden">
      {images.map((image, index) => (
        <div
          key={index}
          className={`absolute inset-0 transition-opacity duration-1000 ${
            index === currentIndex ? "opacity-100" : "opacity-0"
          } bg-cover bg-center`}
          style={{ backgroundImage: `url('${image}')` }}
        ></div>
      ))}

      {/* Text content */}
      <div className="relative z-10 text-start">
        <h1 className="text-3xl md:text-5xl font-bold text-orange-500 pb-5">
          BANKING WITHOUT BORDERS
        </h1>
        <p className="text-black text-xl md:text-2xl">
          move money fast and securely
        </p>
      </div>
    </div>
  );
};

export default Banner;
